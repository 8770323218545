
import { defineComponent, ref } from 'vue';

//import Menubar from 'primevue/menubar';
import Sidebar from 'primevue/sidebar';
import MenuMobile from './MenuMobile.vue';

import Avatar from 'primevue/avatar';

import { useConfirm } from "primevue/useconfirm";
import {  useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useToast } from 'primevue/usetoast';
import useBackground from '@/hooks/useBackground'
import useLogo from '@/hooks/useLogo';
import axios from '@/plugins/axios'




export default defineComponent({
  name: 'Home',
  components: {
   // Menubar,
    Sidebar,
    Avatar,
    MenuMobile,
  },
  setup: () => {
      const store = useStore()
      const menu = ref();
      const router = useRouter();
      const dialog = useConfirm();
      const BackgroundImage = useBackground();
      const logo = useLogo();
      const toast = useToast();
      const paciente = store.state.pacienteLogueado

      //Password
      const oldpassword = ref('');
      const password = ref('');
      const passwordRepeat = ref('');
      const error = ref();
      const editarPass = ref(false);
      const isLoad = ref(false);
  
      const slideVisible = ref(false);
      const itemActive = ref(0);
      const items = ref([
        {
          id:1,
          icon:'pi pi-plus',
          label:'Nuevo Turno',
          to:'nuevo-turno',
          name:'Nuevo Turno'
        },
        {
          id:2,
          icon:'pi pi-list',
          label:'Mis turnos',
          to:'mis-turnos',
          name:'Mis Turnos'
        },
        {
          id:3,
          icon:'pi pi-folder',
          label:'Resultados de estudios',
          to:'resultados',
          name:'Resultados'
        },
        {
          id:4,
          icon:'pi pi-file-o',
          label:'Recetas',
          //to:'recetas',
          name:'Recetas'
        },
        {
          id:5,
          icon:'pi pi-user-edit',
          label:'Mis datos',
          to:'mis-datos',
          name:'Mis Datos'
        },
        {
          icon:'pi pi-lock',
          label:'Cambiar contraseña',
          command: ()=>{
            password.value = ''
            passwordRepeat.value = ''
            oldpassword.value = ''
            error.value = ''
            editarPass.value = true
          }
        },
        {
          icon:'pi pi-sign-out',
          label:'Cerrar sesion',
          command: ()=>{
            dialog.require({
              message:'Seguro que desea cerrar sesion?',
              header:'Cerrar Sesion',
              icon: 'pi pi-exclamation-triangle',
              rejectLabel:'Si',
              acceptLabel:'No',
              acceptClass:'p-button-link',
              rejectClass:'p-button-primary',
              reject: () => {
                store.dispatch('cerrarSesion')
                router.push('login');
              },
            })
          }
        }
      ])
      const dropMenuItems = ref([
        {
          id:5,
          icon:'pi pi-user-edit',
          label:'Mis datos',
          to:'mis-datos',
          command(){
            itemActive.value = this.id;
            document.querySelectorAll('li.p-menuitem').forEach(li => li.classList.remove('active-link'));
            document.querySelector(`li.p-menuitem:nth-child(${this.id})`)?.classList.add('active-link')
          }
        },
        {
          icon:'pi pi-lock',
          label:'Cambiar contraseña',
          command: ()=>{
            password.value = ''
            passwordRepeat.value = ''
            oldpassword.value = ''
            error.value = ''
            editarPass.value = true
          }
        },
        {
          icon:'pi pi-sign-out',
          label:'Cerrar sesion',
          command: ()=>{
            dialog.require({
              message:'Seguro que desea cerrar sesion?',
              header:'Cerrar Sesion',
              icon: 'pi pi-exclamation-triangle',
              rejectLabel:'Si',
              acceptLabel:'No',
              acceptClass:'p-button-link',
              rejectClass:'p-button-primary',
              reject: () => {
                store.dispatch('cerrarSesion')
                router.push('login');
              },
            })
          }
        }
      ])
      
      const validarContraseñas = () => {
          let valida = true;

          valida = valida && password.value === passwordRepeat.value;

          if(!valida){
              error.value = 'Las contraseñas deben ser iguales';
              passwordRepeat.value = '';
              return valida;
          }

          valida = valida && !(password.value.length < 8);

          if(!valida) {
              error.value = 'La contraseña debe tener mas de 8 caracteres';
              password.value = '';
              passwordRepeat.value = '';
              return valida
          }

          return valida;
      }

      const handleClick = () =>{
          error.value = ""
          if(validarContraseñas()){
              isLoad.value = true;
              axios.post('pacientes/cambio-password', {password:password.value, oldpassword: oldpassword.value, idpaciente: paciente.id})
              .then(()=>{
                editarPass.value = false
                toast.add({severity:'success',summary:'Exito',detail:'Se ha actualizado la contraseña correctamente',life:1500})
              })
              .catch(err =>{
                error.value = "La contraseña actual es incorrecta"
              })
              .finally(()=>isLoad.value =false)
          }

      }

      const handleMenuClick = (bool:boolean) => {
        slideVisible.value = bool;
      }

      const handleEditarPassClick = (bool:boolean) =>{
          password.value = ''
          passwordRepeat.value = ''
          oldpassword.value = ''
          error.value = ''
          editarPass.value = bool;
      }
      
      const toggle = (event:Event) => {
            menu.value.toggle(event);
        };

      const handleChange = (option: any) =>{
        store.dispatch('cambiarPaciente', option.value);
        router.push(`/${store.state.configuracionPortal.usuario}/`);
      }
    

    
    return {BackgroundImage, logo, items, slideVisible, itemActive, menu, dropMenuItems, toggle ,
    password,
    passwordRepeat,
    error,
    oldpassword,
    handleMenuClick,editarPass,handleClick,isLoad ,handleEditarPassClick, handleChange}
  }
});
